import onEscapePressMixin from '@vue-storefront/core/mixins/onEscapePress';
import { Component } from '@zento-lib/components';
import { Form } from 'theme/components/Form/Form';
import { Input } from 'theme/components/Form/Input';

import { HeaderItem } from '../../../../atom/HeaderItem/HeaderItem';
import { Overlay } from '../../../../atom/Overlay/Overlay';
import { Button } from '../../../../atom/Button/Button';
import { SearchProducts } from '../../SearchProducts/SearchProducts';
import { SearchCategories } from '../../SearchCategories/SearchCategories';
import { SearchItem } from '../../SearchItem';

import style from './style.scss';

/**
 * Search 6
 *
 * Form Search component allowing to show products and categories.
 **/
@Component({
  mixins: [onEscapePressMixin],
})
@Form()
export class Search6 extends SearchItem {
  showSearch() {
    this.activeSearch.searchState = true;
    this.$data.searchVisible = !this.$data.searchVisible;
    this.$store.commit('ui/setOverlay', false);
  }

  onEscapePress() {
    this.closeSearch();
  }

  updated() {
    if (this.activeSearch.searchState) {
      this.$data.autofocus = true;

      // Keep the input in view for mobile devices that open keyboard
      setTimeout(() => {
        if (this.$refs.searchInput && !this.isDesktop) {
          document.body.scrollIntoView();
        }
      });
    } else {
      this.$data.autofocus = false;
    }
  }

  beforeMount() {
    this.activeSearch.searchState = false;
    this.showSearch = this.showSearch.bind(this);
  }

  render() {
    return (
      <div class={style.searchMiniForm} data-testId='searchMiniForm'>
        <HeaderItem
          label={this.searchButton}
          icon={this.icon}
          onPress={!this.activeSearch.searchState ? this.showSearch : this.closeSearch}
          data-testId='search-icon-mobile'
          key='search-icon-mobile'
        />

        {this.$data.showOverlay || this.$data.searchVisible ? (
          <div onClick={this.closeSearch} class={style.showOverlay} key='search-overlay'>
            <Overlay />
          </div>
        ) : null}

        <transition
          name='slide-in-right'
          enterActiveClass={style.slideInRightEnterActive}
          leaveActiveClass={style.slideInRightLeaveActive}>
          {this.activeSearch.searchState ? (
            <div class={style.searchDrawer}>
              <span class={style.closeSearch} onClick={this.closeSearch} />

              <form
                class={{
                  [style.formSearch]: true,
                  [style.activeSearch]: this.visibleProducts.length > 0,
                  [style.inputError]: this.$data.search.length === 0 && this.$data.error,
                }}
                onSubmit={this.onEnterPress}>
                {this.$data ? (
                  <Input
                    state={this.$data}
                    valueKeeper='search'
                    name='search'
                    type='search'
                    validateOn='input'
                    minLength={0}
                    placeholder={this.getTranslation(this.searchLabel)}
                    labelStyle='inline'
                    autofocusInput={this.$data.autofocus}
                    ref='searchInput'
                    class={style.search}
                    dataTestId='search'
                  />
                ) : null}

                <span onClick={this.closeSearch}>
                  <router-link class={style.button} to={this.goToSearchPage(Search6)} id='searchSubmit'>
                    <HeaderItem
                      label={this.searchButton}
                      icon='search'
                      onPress={() => undefined}
                      class={style.searchBtn}
                      testId='search-icon'
                      key='search-icon'
                    />
                  </router-link>
                </span>

                {this.$data.search.length > 0 ? (
                  <span class={style.closeWrapper}>
                    <span class={style.closeSearch} onClick={this.clearSearchInput} />
                  </span>
                ) : null}
              </form>

              <div class={style.searchWrapper}>
                <div
                  class={{
                    [style.searchAutocompleteActive]: this.visibleProducts.length > 0 || this.categories.length > 0,
                  }}>
                  <div class={style.productListing} data-testId='productListing'>
                    {this.visibleProducts.length > 0 ? (
                      <div>
                        <h4>{this.getTranslation(this.searchProducts)}</h4>
                        <div onClick={this.closeSearch}>
                          <ul>
                            {this.$data.products.map((product, index: number) => {
                              return (
                                <SearchProducts
                                  product={product}
                                  position={index}
                                  class={style.productItem}
                                  key={product.id}
                                />
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    ) : null}

                    {this.categories.length > 0 ? (
                      <div onClick={this.closeSearch} key='search-categories'>
                        <SearchCategories categories={this.categories} class={style.categoriesDrawer} />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>

              {this.visibleProducts.length > 0 && this.$data.total > 3 ? (
                <div class={style.buttonsSet} onClick={this.closeSearch}>
                  {this.$data.readMore ? (
                    <Button styleType='secondary' handler={() => this.$router.push(this.goToSearchPage(''))}>
                      {this.getTranslation(this.searchSeeMore)}( {this.$data.total} )
                    </Button>
                  ) : null}
                </div>
              ) : null}
            </div>
          ) : null}
        </transition>
      </div>
    );
  }

  closeSearch() {
    this.$data.searchVisible = false;
    this.$data.showOverlay = false;
    this.$el.classList.remove(style.mobileActiveSearch);
    document.body.style.overflow = 'visible';
    this.activeSearch.searchState = false;
    this.$store.commit('ui/setOverlay', false);

    this.clearSearchInput();
  }
}
